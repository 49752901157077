import React, { useEffect, useState } from "react";
import {
  Form,
  HyperLink,
  notification,
} from "../../components/Elements/appUtils";
import { branchType } from "../../components/_utils/appUtils";
import PageHeader from "../../components/Elements/pageHeader";
import { useDispatch } from "react-redux";
import { GetEachFormFields } from "../../components/_utils/formUtils";
import { States, gstTypeList } from "../../components/_utils/appUtils";
import { addCompanyFxn } from "../customer/actions";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { singleUserFxn,updateUserFxn } from "./actions";
import { type } from "@testing-library/user-event/dist/type";
const EditDoctor = (props) => {
  const { getFieldValue,setFieldsValue } = props.form;
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const [doctorId, setDoctorId] = useState("");
 
  useEffect(() => {
    checkDoctorId();
  }, []);

  const checkDoctorId = async () => {
    let searchParams = new URLSearchParams(window.location.search);
    let id = searchParams.get("_id");
    if (id) {
      getSingleDoctor(id);
      setDoctorId(id);
    }
  };
  const getSingleDoctor = async (id) => {
    let { data, success } = await dispatch(singleUserFxn(id));
    console.log(data, "valdata")
    if (success) {
      setTimeout(() => {
        props.form.setFieldsValue({
          name: data.name,

         
          mobile: data.mobile,
        
          address: data.address,
          email: data.email,
          state: data.state,
          city: data.city,
          education:data.education
          
        });
       
      
     
      }, 300);
      setTimeout(() => {
        if(data.gstType == "Registered"){
          setFieldsValue({gstIn: data.gstIn})
        }
      }, 400);
      
    }
  };
 

    const handleSubmit = (e) => {
      const {form} = props;
      e.preventDefault();

      form.validateFields(async (err, valData) => {
          if (!err) {

              valData._id = doctorId
            
              let x = await dispatch(updateUserFxn(valData));
              if (x && x.success) {
                  console.log(x, "valdata")
              }
          } else {
              if (err.name) {
                  notification.warning({
                      message: `Please enter Name`,
                  });
                  return;
              }

              if (err.email) {
                  notification.warning({
                      message: `Please enter Email`,
                  });
                  return;
                }

              notification.warning({
                  message: "Fill All Required Fields",
              });
          }
      });
  };

  let inputTypes = {
    fields: [
      {
        key: "name",
        label: "Name",
        span: "col-md-6",
        placeholder: "Doctor Name",
        required: true,
      },
      {
        key: "email",
        label: "Email",
        type: "email",
        span: "col-md-6",
        placeholder: "Email",
        required: true,
      },
     
      {
        key: "mobile",
        label: "Mobile No",
        span: "col-md-6",
        placeholder: "Mobile No",
        required: true,
      },
      {
        key: "address",
        label: "Address",
        span: "col-md-6",
        placeholder: "Address",
        required: false,
        type: "textarea",
      },
      {
        key: "education",
        label: "Education",
        span: "col-md-6",
        placeholder: "Education",
        required: false,
        type: "textarea",
      },
    ],
  };
  return (
    <>
      <PageHeader title={"Edit Doctor"}>
        <div className="card">
          <div className="card-body">
            <Form onSubmit={handleSubmit}>
              <div className="form-elements-wrapper">
                <div className={"row"}>
                  {inputTypes.fields.map((item, key) => {
                    return !item.hidden ? (
                      <div
                        className={`pb-3 ${item.span ? item.span : "col-md-6"}`}
                        key={key}
                      >
                        <GetEachFormFields {...props.form} item={item} />
                      </div>
                    ) : null;
                  })}
                </div>
              </div>

              <button type="submit" className="btn btn-gradient-primary me-2">
                Submit
              </button>
              <HyperLink
                className=" ms-4 btn btn-danger"
                link={"/company-list"}
              >
                Cancel
              </HyperLink>
            </Form>
          </div>
        </div>
      </PageHeader>
    </>
  );
};

export default Form.create()(EditDoctor);
