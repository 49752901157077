import React, {useState, useRef, useEffect} from "react";
import {customAxios as axios} from "../../request";
import {useDispatch} from "react-redux";
import {
    Form,
    notification,
    HyperLink,
} from "../../components/Elements/appUtils";
import {useNavigate} from "react-router-dom";
import {fetchAllEmp} from "../employee/action";
import {addExpenseFxn} from "./actions/index";
import {fetchExpenseCategory} from "./actions/index";
import {fetchBank} from "../customer/actions";
import PageHeader from "../../components/Elements/pageHeader";
import {ExpenseCategory2, PaymentTypes} from "../../components/_utils/appUtils";
import {GetEachFormFields} from "../../components/_utils/formUtils";
import {getGroupDataByCustomerIdUrl} from "../customerGroup/api";

function AddJournalEntry(props) {
    const {getFieldValue} = props.form;
    const [groupName, setGroupName] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [category, setCategory] = useState([]);
    const [clientData, setClientData] = useState([]);
    const [banks, setBanks] = useState([]);
    const paidToUserId = getFieldValue("paidToUserId");


    const getClient = async (params = {}) => {
        params.results = 100;
        params.count = 100;
        const data = await fetchBank({...params});
        setClientData(data.data);
    };

    const fetchBanks = async () => {
        const {data} = await fetchBank({
            type: "bank",
            results: 100,
            count: 100,
        });
        setBanks(data);
    };
    const fetchGroupName = async () => {
        try {
            const response = await axios.get(getGroupDataByCustomerIdUrl(), {
                params: {customerId: paidToUserId},
            });
            setGroupName(response.data.data.name);
        } catch (error) {
            console.error("Error getting the data:", error);
        }
    };
    useEffect(() => {
        props.form.setFieldsValue({
            netAmount: 0,
            paymentType: "None",
        });
        fetchBanks();
    }, []);

    useEffect(() => {
        fetchGroupName();
    }, [paidToUserId]);

    useEffect(() => {
        getClient();
    }, [getFieldValue("category")]);

    const handleSubmit = (e) => {
        const {form} = props;
        e.preventDefault();
        form.validateFields(async (err, valData) => {
            if (!err) {
                if (!valData.fromClient) {
                    notification.warning({message: "Please Select From Client"});
                    return;
                }

                if (!valData.toClient) {
                    notification.warning({message: "Please Select To Client"});
                    return;
                }
                if (!valData.netAmount) {
                    notification.warning({message: "Please Enter Amount"});
                    return;
                }
                if (isNaN(valData.netAmount)) {
                    notification.warning({message: "Please Enter Valid Paid Amount"});
                    return;
                }
                if (!valData.date) {
                    notification.warning({message: "Please Select Date"});
                    return;
                }
                let x = await dispatch(addExpenseFxn(valData));
                if (x && x.success) {
                    navigate("/journal-entry");
                    props.form.setFieldsValue({
                        category: "",
                        date: "",
                    });
                }
            }
        });
    };

    let inputTypes = {
        fields: [
            // {
            //     key: "category",
            //     label: "Expense Category*",
            //     span: "col-md-6",
            //     type: "select",
            //     options: ExpenseCategory2,
            //     showSearch: true,
            //     keyAccessor: (x) => x._id,
            //     valueAccessor: (x) => `${x.category}`,
            //     onChange: (x) => {
            //         props.form.setFieldsValue({
            //             client: "",
            //             category: x,
            //         });
            //     },
            // },
            {
                key: "fromClient",
                label: "From Client (Debit)*",
                span: "col-md-6",
                placeholder: "Select From Client",
                type: "select",
                options: clientData,
                showSearch: true,
                keyAccessor: (x) => x._id,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        fromClient: x,
                    });
                },
            },
            {
                key: "toClient",
                label: "To Client (Credit)* ",
                span: "col-md-6",
                placeholder: "Select To Client",
                type: "select",
                options: clientData,
                showSearch: true,
                keyAccessor: (x) => x._id,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        toClient: x,
                    });
                },
            },
            {
                key: "netAmount",
                label: "Amount*",
                placeholder: "Amount",
                span: "col-md-6",
            },
            {
                key: "date",
                label: "Date*",
                showStar: true,
                placeholder: "Date",
                type: "date",
                span: "col-md-6",
                onChange: (x) => {
                    props.form.setFieldsValue({
                        date: x,
                    });
                },
            },
            {
                key: "paymentDetails",
                label: "Payment Details (Optional)",
                span: "col-md-12",
                placeholder: "Payment Details",
            },
        ],
    };

    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            const data = await fetchExpenseCategory({...params});
            setCategory(data.data);
            resolve(data);
        });
    };

    useEffect(() => {
        apiRequest();
    }, []);

    return (
        <PageHeader title={"Journal Entry"}>
            <Form onSubmit={handleSubmit}>
                <div className="card">
                    <div className="card-body">
                        <div className="form-elements-wrapper">
                            <div className={"row"}>
                                {inputTypes.fields.map((item, key) => {
                                    return !item.hidden ? (
                                        <div
                                            className={`pb-3 ${item.span ? item.span : "col-md-6"}`}
                                            key={key}
                                        >
                                            <GetEachFormFields {...props.form} item={item}/>
                                        </div>
                                    ) : null;
                                })}
                            </div>
                        </div>

                        <button type="submit" className="btn btn-gradient-info me-2">
                            Submit
                        </button>
                        <HyperLink className=" ms-4 btn btn-danger" link={"/journal-entry"}>
                            Cancel
                        </HyperLink>
                    </div>
                </div>
            </Form>
        </PageHeader>
    );
}

export default Form.create()(AddJournalEntry);
