import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  Tooltip,
  Popconfirm,
  Modal,
} from "../../components/Elements/appUtils";
import PageHeader from "../../components/Elements/pageHeader";
import { fetchCustomer } from "./actions";
import { deleteCustomerUrl, getTransaction } from "./api";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../request";
import {
  notification,
  DefaultTablePagination,
} from "../../components/Elements/appUtils";
import axios from "axios";
import _ from "lodash";
import TransactionComp from "../../components/transactionComp"

const CustomerList = () => {
  const [customerData, setCustomerData] = useState([]);
  const [customerId, setCustomerId] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [report, setReport] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [searchName, setSearchName] = useState("");
  const [searchEmail, setSearchEmail] = useState("");
  const [searchMobile, setSearchMobile] = useState("");
  const [searchGst, setSearchGst] = useState("");
  const [searchGroup, setSearchGroup] = useState("");
  const [searchAddress, setSearchAddress] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  let tableRef = useRef();
  const navigate = useNavigate();

  const [customerDetails, setCustomerDetails] = useState({
    customerName: "",
    customerState: "",
    customerInvoice: "",
    customerNumber: "",
    purchaseDate: "",
    balance: "",
  });

  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      params.type = "customer";
      if (searchName){
        params.name = searchName
      }
      if (searchEmail){
        params.email = searchEmail
      }
      if (searchMobile){
        params.mobile = searchMobile
      }
      if (searchGroup){
        params.customerGroupName = searchGroup
      }
      // if (searchAddress){
      //   params.address = searchAddress
      // }
      const data = await fetchCustomer( params );
      setCustomerData(data.data);
      setFilteredData(data.data);
      resolve(data);
      
    });
  };

  let loadColumn = () => {
    let columnList = [];
    _.each(columns, (item) => {
      if (!item.hidden) {
        columnList.push(item);
      }
    });
    return columnList;
  };

  const fetchcustomerdata = () => {
    const allrecordofcustomer = customerData.filter(
      (item) => item._id == customerId
    );

    allrecordofcustomer.map((i) => {
      setCustomerDetails({
        customerName: i.name,
        customerState: i.state,
        customerNumber: i.mobile,
        customerInvoice: i.invoiceNumber,
        purchaseDate: i.purchaseDate,
      
        balance: i.balance,
      });
   
      return;
    });
  };
  useEffect(() => {

    fetchcustomerdata();
  }, [customerId]);
  const deleteCustomer = async (id) => {
    try {
      let config = {
        ...(await getToken()),
      };
      const response = await axios.delete(deleteCustomerUrl(id), config);
      console.log(response, "response");
      if (response.data.error) {
        notification.error({
          message: response.data.message || "Error",
        });
      } else {
        tableRef.current.reload();
        notification.success({
          message: response.data.message,
        });
      }
    } catch (error) {
      console.error("Error getting the data:", error);
    }
  };

  const fetchTransaction = async (dispach) => {
    try {
      let config = {
        params: { customerId },
        ...(await getToken()),
      };

      const response = await axios.get(getTransaction(customerId), config);
      let filter = response.data.data.reverse()
      setReport(filter);
    } catch (error) {
      console.error("Error getting the data:", error);
    }
  };

  useEffect(() => {
    fetchTransaction();
  }, [customerId ]);

  useEffect(() => {
    if (isModalOpen) {
      fetchTransaction();
    }
  }, [isModalOpen]);

  console.log(report, "report");
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Number",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "GST Type",
      dataIndex: "gstType",
      key: "gstType",
    },
    {
      title: "Group",
      dataIndex: "customerGroupName",
      key: "customerGroupName",
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
    },

    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      render: (text, record) => (
        <>
          {record.shippingAddress}, {record.shippingCountry},{" "}
          {record.shippingPincode}
        </>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 190,
      render: (val, record) => {
        return (
          <>
            <a
              onClick={() => {
                navigate(`/edit-customer?_id=${record._id}`);
              }}
            >
              <img src={"./edit.svg"} />
            </a>
            <Tooltip title={"Delete"} className="ml-4">
              <Popconfirm
                title={"Are your sure, you want to delete Customer?"}
                okText="Yes"
                cancelText="No"
                onConfirm={() => {
                  deleteCustomer(record._id);
                }}
              >
                <img src={"./close.svg"} />
              </Popconfirm>
            </Tooltip>
            <Tooltip title={"View"}>
              <a
                className={"empty_btn"}
                onClick={() => {
                  setIsModalOpen(true);
                  setCustomerId(record._id);
                }}
              >
                <i className={"fa fa-eye far"}></i>
              </a>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const filterCustomer = () => {
    // setShowFilter(true);
    // if (
    //   !searchName &&
    //   !searchEmail &&
    //   !searchMobile &&
    //   !searchGst &&
    //   !searchGroup &&
    //   !searchAddress
    // ) {
    //   // setCustomerData([])
    //   notification.error({ message: "please enter name" });
    //   setShowFilter(false);
    //   return;
    // }
    // const filteredCustomer = customerData.filter((customer) => {
    //   return (
    //     (customer.name?.toLowerCase().includes(searchName.toLowerCase()) ||
    //       searchName === "") &&
    //     (customer.email?.toLowerCase().includes(searchEmail.toLowerCase()) ||
    //       searchEmail === "") &&
    //     (customer.mobile?.toLowerCase().includes(searchMobile.toLowerCase()) ||
    //       searchMobile === "") &&
    //     (customer.gstType?.toLowerCase().includes(searchGst.toLowerCase()) ||
    //       searchGst === "") &&
    //     (customer.customerGroupName
    //       ?.toLowerCase()
    //       .includes(searchGroup.toLowerCase()) ||
    //       searchGroup === "") &&
    //     ((typeof customer.address === "string" &&
    //       customer.address.includes(searchAddress)) ||
    //       searchAddress === "")
    //   );
    // });
    //
    // setCustomerData(filteredCustomer);
    tableRef.current.reload()
  };

  const clearCustomerFilters = () => {
    setSearchName("");
    setSearchEmail("");
    setSearchMobile("");
    setSearchGst("");
    setSearchGroup("");
    setSearchAddress("");
    setShowFilter(false);
    setCustomerData(filteredData);
  };
  return (
    <PageHeader
      title={"Customer"}
      extraLink={[
        {
          name: "Add Customer",
          link: "/add-customer",
        },
      ]}
    >
      <div className="card">
        <div className="card-body">
          <div className="mb-3 row">
            <div className="col-md-2 mb-2 pe-0" style={{ paddingLeft: "7px" }}>
              <label htmlFor="searchName" className="form-label" />

              <input
                type="text"
                className="form-control search-filters"
                id="searchName"
                placeholder="Search by Name"
                value={searchName}
                onChange={(e) => setSearchName(e.target.value)}
              />
            </div>
            <div className="col-md-2 mb-2 pe-0" style={{ paddingLeft: "7px" }}>
              <label htmlFor="searchEmail" className="form-label" />
              <input
                type="text"
                className="form-control search-filters"
                id="searchEmail"
                placeholder="Search by email"
                value={searchEmail}
                onChange={(e) => setSearchEmail(e.target.value)}
              />
            </div>
            <div className="col-md-2 mb-2 pe-0" style={{ paddingLeft: "7px" }}>
              <label htmlFor="searchMobile" className="form-label" />
              <input
                type="text"
                className="form-control search-filters"
                id="searchMobile"
                placeholder="Search by Mobile"
                value={searchMobile}
                onChange={(e) => setSearchMobile(e.target.value)}
              />
            </div>
            <div className="col-md-2 mb-2 pe-0" style={{ paddingLeft: "7px" }}>
              <label htmlFor="searchGroup" className="form-label" />
              <input
                type="text"
                className="form-control search-filters"
                id="searchGroup"
                placeholder="Search by Group"
                value={searchGroup}
                onChange={(e) => setSearchGroup(e.target.value)}
              />
            </div>
           {/* <div className="col-md-2 mb-2 pe-0" style={{ paddingLeft: "7px" }}>
              <label htmlFor="searchAddress" className="form-label" />
              <input
                type="text"
                className="form-control search-filters"
                id="searchAddress"
                placeholder="Search by Address"
                value={searchAddress}
                onChange={(e) => setSearchAddress(e.target.value)}
              />
            </div>*/}
          </div>

          <div className="mb-3 search-buttons">
            <div className="search-wrap pe-2">
              <button className="btn btn-info" onClick={filterCustomer}>
                Search
              </button>
            </div>
            <div className="search-wrap">
              <button
                className="btn btn-secondary"
                onClick={clearCustomerFilters}
              >
                Clear
              </button>
            </div>
          </div>
            <Table
              apiRequest={apiRequest}
              columns={loadColumn()}
              ref={tableRef}
              pagination={DefaultTablePagination()}
            />
        </div>
      </div>
      {isModalOpen ? (
        <TransactionComp
          visible={isModalOpen}
          Report={report}
          customerDetails={customerDetails}
          onClose={() => {
            setIsModalOpen(false);
              setReport([]);
              
          }}
        />
      ) : null}
    </PageHeader>
  );
};

export default CustomerList;
