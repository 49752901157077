import {addCompanyUrl, getCompanyDataUrl,singleCompanyUrl,updateCompanyUrl} from "../api";
import {customAxios as axios, getToken} from "../../../request";
import {hideLoader, showLoader} from "../../../actions/loader";
import {notification} from "../../../components/Elements/appUtils";
import { updateProfileUrl } from "../api";
export const fetchCompany = async (valData) => {
    let config = {
        params: {...valData}
    }
    const {data} = await axios.get(getCompanyDataUrl(), config);
    return data;
};

export const addCompanyFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    const {data} = await axios.post(addCompanyUrl(), valData,getToken ());
    dispatch(hideLoader())
    if (!data.error) {
        notification.success({message: data.message || "Success"})
    } else {
        notification.error({message: data.message || "Error"})
    }
    return data;
};

export const singleCompanyFxn = (id) => async (dispatch) => {
    dispatch(showLoader());
    
    let { data } = await axios.get(singleCompanyUrl(id),getToken ());
    console.log(data);
    dispatch(hideLoader());
    if (data.error) {
      notification.error({
        message: data.message || "Error",
      });
    }
  
    return data;
  };

  export const updateCompanyFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let config = {
        ...(await getToken()),
      };
    let {data} = await axios.post(updateCompanyUrl(), valData, config);
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
  };

  export const updateProfileFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let config = {
        ...(await getToken()),
      };
    let {data} = await axios.post(updateProfileUrl(), valData , config);
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
  };
