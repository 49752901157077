import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { getItemLocalstorage } from "../../components/_utils/_utils";
const image2 = require("../../components/assets/images/logo.png");
const image3 = require("../../components/assets/images/faces/face3.jpg");
const image4 = require("../../components/assets/images/logo1.png");

export default function Header(props) {
  let { currentUser } = props;
  let navigate = useNavigate();
  const logout = () => {
    localStorage.clear();
    setTimeout(() => {
      navigate("/");
      window.location.reload();
    }, 1000);
  };
  const handleEditProfile = () => {
    window.location.href = "/edit-profile";
  };
  const checkUserType = getItemLocalstorage("user")["userType"];
  return (
    <>
      <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
          <a className="navbar-brand brand-logo" href="/dashboard">
            <img
              src={image2}
              alt="logo"
              style={{ width: "auto", height: 55 }}
            />
          </a>
          <a className="navbar-brand brand-logo-mini" href="/dashboard">
            <img src={image2} alt="logo" />
          </a>
        </div>
        <div className="navbar-menu-wrapper d-flex align-items-stretch">
          <ul className="navbar-nav navbar-nav-right">
            <li className="nav-item nav-profile dropdown">
              <a
                className="nav-link dropdown-toggle"
                id="profileDropdown"
                href="#"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <div className="nav-profile-text">
                  {currentUser && currentUser.companyName ? (
                    <>
                      <p
                        className="mb-1 text-black"
                        style={{ textTransform: "capitalize" }}
                      >
                        {currentUser && currentUser.companyName
                          ? currentUser.companyName
                          : ""}
                      </p>
                    </>
                  ) : (
                    <>
                      <p
                        className="mb-1 text-black"
                        style={{ textTransform: "capitalize" }}
                      >
                        {currentUser && currentUser.name
                          ? currentUser.name
                          : ""}
                      </p>
                    </>
                  )}
                </div>
              </a>

              <div
                className="dropdown-menu navbar-dropdown"
                aria-labelledby="profileDropdown"
              >
           
           
                {checkUserType == "purchaseUser" ||
                checkUserType == "transferUser" ||
                checkUserType == "reception" ||
                checkUserType == "doctor" ? (
                  ""
                ) : (
                  <>
                    <a className="dropdown-item" onClick={handleEditProfile}>
                      <i className="mdi mdi-logout me-2 text-primary"></i> Edit Profile{" "}
                    </a>
                  </>
                )}

                <a className="dropdown-item" onClick={logout}>
                  <i className="mdi mdi-logout me-2 text-primary"></i> Logout{" "}
                </a>
              </div>
            </li>

            {/*   <li className="nav-item nav-logout d-none d-lg-block">
                        <div className="nav-profile-text">
                            <p className="mb-1 text-black">{currentUser && currentUser.companyName ? currentUser.companyName : ""}</p>
                        </div>
                    </li>

                    <li className="nav-item nav-logout d-none d-lg-block">
                        <a className="nav-link" onClick={logout}>
                            <i className="mdi mdi-power"></i>
                        </a>
                    </li>*/}
            {/* <li className="nav-item nav-settings d-none d-lg-block">
                        <a className="nav-link">
                            <i className="mdi mdi-format-line-spacing"></i>
                        </a>
                    </li>*/}
          </ul>
          <button
            className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
            type="button"
            data-toggle="offcanvas"
          >
            <span className="mdi mdi-menu"></span>
          </button>
        </div>
      </nav>
    </>
  );
}
