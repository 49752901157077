import React, { useEffect, useRef, useState } from "react";
import { Modal, Table, Tooltip } from "../../components/Elements/appUtils";
import { fetchCreditNote, fetchSale } from "./actions";
import PageHeader from "../../components/Elements/pageHeader";
import moment from "moment";
import { getToken } from "../../request";
import { getProductReport } from "../purchase/api";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../actions/loader";

function CreditNoteList() {
  let tableRef = useRef();
  const dispatch =  useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [creditNoteId, setCreditNoteId] = useState(null);
  const [Report, setReport] = useState([]);
  const navigate = useNavigate();
  const [customerData, setCustomerData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [showFilter, setShowFilter] = useState(false)
  const [searchVendor, setSearchVendor] = useState("")
  const [searchInvoice, setSearchInvoice] = useState("")
  const [searchCreditDate, setSearchCreditDate] = useState("")
  const [searchState, setSearchState] = useState("")
  const [customerDetails, setCustomerDetails] = useState({
    customerName: "",
    customerState: "",
    customerInvoice: "",
    customerNumber: "",
    purchaseDate: "",
  });

  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      try {
        params = { results: 10000 };
        const data = await fetchCreditNote({ ...params });
        resolve(data);
        setCustomerData(data.data);
        console.log(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    });
  };

  const fetchcustomerdata = () => {
    const allrecordofcustomer = customerData.filter(
      (item) => item._id == creditNoteId
    );

    allrecordofcustomer.map((i) => {
      console.log(i,"cafpjpj");
      setCustomerDetails({
        customerName: i.customerId.name,
        customerState: i.customerId.state,
        customerNumber: i.customerId.mobile,
        customerInvoice: i.invoiceNumber,
        purchaseDate: i.purchaseDate,
      });
      return;
    });
  };

  const fetchProductReport = async () => {
    try {
      let config = {
        params: { creditNoteId },
        ...(await getToken()),
      };
      dispatch(showLoader())
      const response = await axios.get(getProductReport(), config);
      setReport(response.data.data);
      dispatch(hideLoader())
    } catch (error) {
      console.error("Error getting the data:", error);
    }
  };

  useEffect(() => {
    apiRequest();
  }, []);

  useEffect(() => {
    fetchProductReport();
    fetchcustomerdata();
  }, [creditNoteId]);

  const columns = [
    {
      title: "Sr. No",
      dataIndex: "serialNo",
      key: "serialNo",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Vendor",
      dataIndex: "customerId",
      key: "customerId",
      render: (text) => <span>{text?.name}</span>,
    },
    {
      title: "Invoice Number",
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
    },
    {
      title: "Credit Note Date",
      dataIndex: "creditNoteDate",
      key: "creditNoteDate",
      render: (text) => <span>{moment(text).format("DD/MMM/YY")}</span>,
    },
    {
      title: "State",
      dataIndex: "customerId",
      key: "customerId",
      render: (text) => <span>{text?.state}</span>,
    },
    {
      title: "Total Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Total Amount",
      dataIndex: "totalAmount",
      key: "totalAmount",
      render: (text) => <span>Rs. {text}</span>,
    },
    {
      title: "Recieved Amount",
      dataIndex: "paidAmount",
      key: "paidAmount",
      render: (text) => <span>Rs. {text}</span>,
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
      render: (text) => <span>Rs. {text}</span>,
    },
    {
      title: "Actions",
      dataIndex: "description",
      key: "description",
      render: (text, record) => (
        <>
          <Tooltip title={"View"}>
            <a
              className={"empty_btn"}
              onClick={() => {
                setIsModalOpen(true);
                setCreditNoteId(record._id);
              }}
            >
              <i className={"fa fa-eye far "}></i>
            </a>
          </Tooltip>
        </>
      ),
    },
  ];
  const filterCreditNote = () => {
    setShowFilter(true);

    if (!searchVendor && !searchInvoice && !searchCreditDate && !searchState) {
      setCustomerData([]);
    } else {
      const filteredCreditNote = customerData.filter((credit) =>
        credit.customerId.name.toLowerCase().includes(searchVendor.toLowerCase()) &&
        credit.invoiceNumber.toLowerCase().includes(searchInvoice.toLowerCase()) &&
        credit.creditNoteDate.toLowerCase().includes(searchCreditDate.toLowerCase()) &&
        credit.customerId.state.toLowerCase().includes(searchState.toLowerCase())
      );
      setCustomerData(filteredCreditNote);
    }
  };

  const clearCreditFilters = () => {
    setSearchVendor("");
    setSearchInvoice("");
    setSearchCreditDate("");
    setSearchState("");
    setShowFilter(false);
    setCustomerData(filteredData);
  };

  return (
    <PageHeader
      title={"Credit-Note"}
      extraLink={[
        {
          name: "Add Credit-Note",
          link: "/addcreditNote",
        },
      ]}
    >
      <div className="card">
        <div className="card-body">
        <div className="mb-3 row">
        <div className="col-md-2 mb-2 pe-0" style={{ paddingLeft: '7px' }}>
          <label htmlFor="searchVendor" className="form-label" />
          <input
            type="text"
            className="form-control search-filters"
            id="searchVendor"
            placeholder="Search by Vendor"
            value={searchVendor}
            onChange={(e) => setSearchVendor(e.target.value)}
          />
        </div>

        <div className="col-md-2 mb-2 pe-0" style={{ paddingLeft: '7px' }}>
          <label htmlFor="searchInvoice" className="form-label" />
          <input
            type="text"
            className="form-control search-filters"
            id="searchInvoice"
            placeholder="Search by Invoice"
            value={searchInvoice}
            onChange={(e) => setSearchInvoice(e.target.value)}
          />
        </div>

        <div className="col-md-2 mb-2 pe-0" style={{ paddingLeft: '7px' }}>
          <label htmlFor="searchCreditDate" className="form-label" />
          <input
            type="text"
            className="form-control search-filters"
            id="searchCreditDate"
            placeholder="Search by CreditDate"
            value={searchCreditDate}
            onChange={(e) => setSearchCreditDate(e.target.value)}
          />
        </div>
        <div className="col-md-2 mb-2 pe-0" style={{ paddingLeft: '7px' }}>
          <label htmlFor="searchState" className="form-label" />
          <input
            type="text"
            className="form-control search-filters"
            id="searchState"
            placeholder="Search by State"
            value={searchState}
            onChange={(e) => setSearchState(e.target.value)}
          />
        </div>
      </div>

      <div className="mb-3 search-buttons">
        <div className="search-wrap pe-2">
          <button className="btn btn-info" onClick={filterCreditNote}>
            Search
          </button>
        </div>
        <div className="search-wrap">
          <button className="btn btn-secondary" onClick={clearCreditFilters}>
            Clear
          </button>
        </div>
      </div>

      {showFilter ? (
        <Table
          dataSource={customerData}
          columns={columns}
          ref={tableRef}
          key={`table-${showFilter ? 'filtered' : 'original'}`}
        />
      ) : (
        <Table
          apiRequest={apiRequest}
          columns={columns}
          ref={tableRef}
        />
      )}
        </div>
      </div>
      {isModalOpen && (
        <Modal
          title={"Product List"}
          width={"70%"}
          visible={isModalOpen === true}
          onClose={() => setIsModalOpen(false)}
        >
          <div className="d-inline-block border border-2">
            <div className="item-flex">
              <h5 className="m-3 d-block">
                Name:{" "}
                <span className="font-weight-normal ms-2">
                  {customerDetails.customerName}
                </span>
              </h5>
              <h5 className="m-3 d-block">
                State:{" "}
                <span className="font-weight-normal ms-2">
                  {customerDetails.customerState}
                </span>
              </h5>
              <h5 className="m-3 d-block">
                Invoice No:{" "}
                <span className="font-weight-normal ms-2">
                  {customerDetails.customerInvoice}
                </span>
              </h5>
              <h5 className="m-3 d-block">
                Number:{" "}
                <span className="font-weight-normal ms-2">
                  {customerDetails.customerNumber}
                </span>
              </h5>
              <h5 className="m-3 d-block">
                Date:{" "}
                <span className="font-weight-normal ms-2">
                  {moment(customerDetails.purchaseDate).format("DD-MMM-YYYY")}
                </span>
              </h5>
            </div>
          </div>
          <table className="table table-bordered mt-4">
            <thead>
              <tr>
                <td>Sr no.</td>
                <td>Product Name</td>
                <td>Report Type</td>
                <td>Mfg Date</td>
                <td>Exp Date</td>
                <td>Batch</td>
                <td>Qty.</td>
                <td>Rate</td>
                <td>Dis Amt.</td>
                <td>Taxable Amt.</td>
                {Report.some(
                  (item) => item.cgstAmount !== 0 && item.sgstAmount !== 0
                ) ? (
                  <>
                    <td>CGST Amt.</td>
                    <td>SGST Amt.</td>
                  </>
                ) : (
                  <td>IGST Amt.</td>
                )}
                <td>Net Amt.</td>
              </tr>
            </thead>
            <tbody>
              {Report.map((item, index) => (
                <tr key={item.id}>
                  <td>{index + 1}</td>
                  <td>{item.productName}</td>
                  <td>{item.reportType}</td>
                  <td>{item.manufacturingDate}</td>
                  <td>{item.expiryDate}</td>
                  <td>{item.batchNo}</td>
                  <td>{item.quantity}</td>
                  <td>Rs. {item.price}</td>
                  <td>
                    Rs. {item.discountAmount} ({item.discount}%)
                  </td>
                  <td>Rs. {item.taxableAmount}</td>
                  {item.cgstAmount === 0 && item.sgstAmount === 0 ? (
                    <>
                      <td>
                        Rs. {item.igstAmount} ({item.gst}%)
                      </td>
                    </>
                  ) : (
                    <>
                      <td>
                        Rs. {item.cgstAmount} ({item.gst / 2}%)
                      </td>
                      <td>
                        Rs. {item.sgstAmount} ({item.gst / 2}%)
                      </td>
                    </>
                  )}

                  <td>Rs. {item.netAmount}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal>
      )}
    </PageHeader>
  );
}

export default CreditNoteList;
