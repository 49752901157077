import React, {useEffect, useRef, useState} from "react";
import {
    InputBox,
    Modal,
    Popconfirm,
    Table,
    Tooltip,
} from "../../components/Elements/appUtils";
import {fetchSale} from "./actions";
import PageHeader from "../../components/Elements/pageHeader";
import moment from "moment";
import {getToken} from "../../request";
import {getProductReport, statusChangeUrl} from "../purchase/api";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {hideLoader, showLoader} from "../../actions/loader";
import {getItemLocalstorage} from "../../components/_utils/_utils";
import {DefaultTablePagination} from "../../components/Elements/appUtils";
import SearchCustomer from "../customer/chooseCustomer/searchCustomer";
import {fetchSaleCategory} from "../category/actions";
import {getCityByState, getStateByCountry, shipthrough} from "../../components/_utils/appUtils";

const ProductReportList = (props) => {
    let {customerDetails, Report, visible, onClose, paymentRecord} = props;
    return (
        <>
            <Modal
                title={"Product List"}
                width={"80%"}
                visible={visible}
                onClose={onClose}
            >
                <div className={"mb50 bordered"}>
                    {customerDetails ? (
                        <div className="d-inline-block border border-2">
                            <div className="item-flex">
                                <h5 className="m-3 d-block">
                                    Name:{" "}
                                    <span className="font-weight-normal ms-2">
                    {customerDetails.customerName}
                  </span>
                                </h5>
                                <h5 className="m-3 d-block">
                                    State:{" "}
                                    <span className="font-weight-normal ms-2">
                    {customerDetails.customerState}
                  </span>
                                </h5>
                                <h5 className="m-3 d-block">
                                    Invoice No:{" "}
                                    <span className="font-weight-normal ms-2">
                    {customerDetails.customerInvoice}
                  </span>
                                </h5>
                                <h5 className="m-3 d-block">
                                    Number:{" "}
                                    <span className="font-weight-normal ms-2">
                    {customerDetails.customerNumber}
                  </span>
                                </h5>
                                <h5 className="m-3 d-block">
                                    Date:{" "}
                                    <span className="font-weight-normal ms-2">
                    {moment(customerDetails.purchaseDate).format("DD-MMM-YYYY")}
                  </span>
                                </h5>
                                <h5 className="m-3 d-block">
                                    Tracking Details:{" "}
                                    <span className="font-weight-normal ms-2">
                    {customerDetails.trackingDetails}
                  </span>
                                </h5>
                                <h5 className="m-3 d-block">
                                    Ship Through:{" "}
                                    <span className="font-weight-normal ms-2">
                    {customerDetails.shipThrough}
                  </span>
                                </h5>
                            </div>
                        </div>
                    ) : null}
                    {Report && Report.length ? (
                        <div
                            className="table-wrapper mt-4"
                            style={{
                                maxHeight: "200px",
                                overflowX: "auto",
                                overflowY: "auto",
                            }}
                        >
                            <table className="table table-bordered mt-4">
                                <thead>
                                <tr>
                                    <td>Sr no.</td>
                                    <td>Product Name</td>
                                    <td>Report Type</td>
                                    <td>Mfg Date</td>
                                    <td>Exp Date</td>
                                    <td>Batch</td>
                                    <td>Qty.</td>

                                    <td>Sale Price</td>
                                    <td>Price</td>
                                    <td>Dis Amt.</td>
                                    <td>Taxable Amt.</td>
                                    {customerDetails.cgstAmount !== 0 &&
                                    customerDetails.sgstAmount !== 0 ? (
                                        <>
                                            <td>CGST Amt.</td>
                                            <td>SGST Amt.</td>
                                        </>
                                    ) : customerDetails.igstAmount !== 0 ? (
                                        <td>IGST Amt.</td>
                                    ) : (
                                        <>
                                            <td>CGST Amt.</td>
                                            <td>SGST Amt.</td>
                                        </>
                                    )}
                                    <td>Net Amt.</td>
                                </tr>
                                </thead>
                                <tbody>
                                {Report &&
                                Report.map((item, index) => (
                                    <tr key={item.id}>
                                        <td>{index + 1}</td>
                                        <td>{item.productName}</td>
                                        <td>{item.reportType}</td>
                                        <td>{item.manufacturingDate}</td>
                                        <td>{item.expiryDate}</td>
                                        <td>{item.batchNo}</td>
                                        <td>{item.quantity}</td>
                                        <td>Rs. {item.salePrice}</td>
                                        <td>Rs. {item.price}</td>

                                        <td>
                                            Rs. {item.discountAmount} ({item.discount}%)
                                        </td>
                                        <td>Rs. {item.taxableAmount}</td>
                                        {customerDetails.igstAmount === 0 ? (
                                            <>
                                                <td>
                                                    Rs. {item.cgstAmount} ({item.gst / 2}%)
                                                </td>
                                                <td>
                                                    Rs. {item.sgstAmount} ({item.gst / 2}%)
                                                </td>
                                            </>
                                        ) : customerDetails.cgstAmount === 0 &&
                                        customerDetails.sgstAmount === 0 ? (
                                            <td>
                                                Rs. {item.igstAmount} ({item.gst}%)
                                            </td>
                                        ) : (
                                            <>
                                                <td>
                                                    Rs. {item.cgstAmount} ({item.gst / 2}%)
                                                </td>
                                                <td>
                                                    Rs. {item.sgstAmount} ({item.gst / 2}%)
                                                </td>
                                            </>
                                        )}

                                        <td>Rs. {item.netAmount}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    ) : null}
                </div>

                <div className="Total-section mt-4">
                    <div className="row">
                        <div className="col-sm-7 mt-1">
                            <h6 style={{fontSize: "13px"}}>Payment Details:</h6>
                            <div
                                style={{
                                    borderBottom: "1px solid #000",
                                    marginTop: "10px",
                                    width: "300px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <span style={{fontSize: "13px"}}>PAYMODE</span>
                                <span style={{fontSize: "13px"}}>AMOUNT</span>
                            </div>

                            <div>
                                {paymentRecord.map((val, innerIndex) => (
                                    <div
                                        key={innerIndex}
                                        style={{
                                            marginTop: "10px",
                                            width: "300px",
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <span style={{fontSize: "13px"}}>{val.paymentType}</span>
                                        <span style={{fontSize: "13px"}}>{val.amount}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="col-sm-5 border-design">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="row">
                                        <div className="col-sm-7">
                                            <div style={{textAlign: "right"}}>
                                                <p style={{fontSize: "13px"}}>
                                                    <b>Taxable Amt :</b>
                                                </p>
                                                {customerDetails.cgstAmount !== 0 &&
                                                customerDetails.sgstAmount !== 0 ? (
                                                    <>
                                                        <p style={{fontSize: "13px"}}>
                                                            <b>CGST Total :</b>{" "}
                                                        </p>
                                                        <p style={{fontSize: "13px"}}>
                                                            <b>SGST Total :</b>{" "}
                                                        </p>
                                                    </>
                                                ) : (
                                                    <p style={{fontSize: "13px"}}>
                                                        <b>IGST Total : </b>
                                                    </p>
                                                )}
                                                <p style={{fontSize: "13px"}}>
                                                    <b>Cut Off Amount :</b>
                                                </p>
                                                <p style={{fontSize: "13px"}}>
                                                    <b>Cash Discount :</b>
                                                </p>
                                                <p style={{fontSize: "13px"}}>
                                                    <b>Total Amt :</b>
                                                </p>
                                                <div
                                                    style={{
                                                        borderBottom: "1px solid #000",
                                                        marginTop: "7px",
                                                        width: "185%",
                                                        marginLeft: "10px",
                                                        textAlign: "right",
                                                    }}
                                                />
                                                <p style={{fontSize: "13px"}}>
                                                    <b>Paid Amt :</b>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-sm-5">
                                            <div style={{textAlign: "right"}}>
                                                <p style={{fontSize: "13px"}}>
                                                    {" "}
                                                    ₹{" "}
                                                    {parseFloat(customerDetails.taxableAmount).toFixed(2)}
                                                </p>
                                                {customerDetails.cgstAmount !== 0 &&
                                                customerDetails.sgstAmount !== 0 ? (
                                                    <>
                                                        <p style={{fontSize: "13px"}}>
                                                            {" "}
                                                            ₹{" "}
                                                            {parseFloat(customerDetails.cgstAmount).toFixed(
                                                                2
                                                            )}
                                                        </p>
                                                        <p style={{fontSize: "13px"}}>
                                                            {" "}
                                                            ₹{" "}
                                                            {parseFloat(customerDetails.sgstAmount).toFixed(
                                                                2
                                                            )}
                                                        </p>
                                                    </>
                                                ) : (
                                                    <p style={{fontSize: "13px"}}>
                                                        {" "}
                                                        ₹{" "}
                                                        {parseFloat(customerDetails.igstAmount).toFixed(2)}
                                                    </p>
                                                )}
                                                <p style={{fontSize: "13px"}}>
                                                    ₹{" "}
                                                    {parseFloat(customerDetails.cutOffAmount).toFixed(2)}
                                                </p>
                                                <p style={{fontSize: "13px"}}>
                                                    ₹{" "}
                                                    {parseFloat(customerDetails.customDiscount).toFixed(
                                                        2
                                                    )}
                                                </p>
                                                <p style={{fontSize: "13px"}}>
                                                    ₹ {parseFloat(customerDetails.netAmount).toFixed(2)}
                                                </p>
                                                <p style={{fontSize: "13px"}}>
                                                    ₹ {customerDetails.paidAmount}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

function Sale() {
    const checkUserType = getItemLocalstorage("user")["userType"];
    const userSaleCategory = getItemLocalstorage("user")["saleCategory"];

    let tableRef = useRef();
    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [shippingModal, setShippingModal] = useState(false);
    const [salesId, setSalesId] = useState(null);
    const [Report, setReport] = useState([]);
    const navigate = useNavigate();
    const [customerData, setCustomerData] = useState([]);

    const [searchSaleCategory, setSearchSalesCategory] = useState("");
    const [searchbyPaymentStatus, setSearchByPaymentStatus] = useState("");
    const [searchType, setSearchType] = useState("");
    const [paymentRecord, setPaymentRecord] = useState([]);
    const [trackingDetails, setTrackingDetails] = useState("");
    const [shipThrough, setShipThrough] = useState("");
    const [customerId, setCustomerId] = useState("");
    const [searchByOrderStatus, setSearchByOrderStatus] = useState("");
    const [searchByState, setSearchByState] = useState({
        stateCode:'',
        stateName:''
    });
    const [saleCategory, setSaleCategory] = useState([]);
    const [stateList, setStateList] = useState([]);

    let [k, setk] = useState("");
    let [dates, setDates] = useState({
        fromDate: "",
        toDate: "",
    });

    const [customerDetails, setCustomerDetails] = useState({
        customerName: "",
        customerState: "",
        customerInvoice: "",
        customerNumber: "",
        purchaseDate: "",
        taxableAmount: "",
        sgstAmount: "",
        cgstAmount: "",
        igstAmount: "",
        gstAmount: "",
        totalAmount: "",
        paidAmount: "",
        netAmount: "",
        cutOffAmount: "",
        customDiscount: "",
        finalAmount: "",
    });

    const fetchCategoryFrom = async () => {
        const {data} = await fetchSaleCategory({});
        setSaleCategory(data);
    };
    useEffect(() => {
        fetchCategoryFrom();
    }, []);
    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            try {
                if (searchSaleCategory) {
                    params.saleCategoryName = searchSaleCategory;
                }
                if (searchbyPaymentStatus) {
                    params.paymentStatus = searchbyPaymentStatus;
                }

                if (userSaleCategory && !searchSaleCategory) {
                    params.saleCategory = userSaleCategory;
                }

                if (searchType && searchType !== "customer") {
                    params.type = searchType;
                }

                if (customerId) {
                    params.customerId = customerId;
                }
                if (searchByOrderStatus) {
                    params.orderStatus
                        = searchByOrderStatus;
                }
                if (searchByState.stateName && searchByState.stateCode) {
                    params.stateOfSupply = searchByState.stateCode;
                    // params.state = searchByState.stateName;
                }

                if (dates.fromDate && dates.toDate) {
                    const fromDateStartOfDay = new Date(dates.fromDate);
                    fromDateStartOfDay.setHours(0, 0, 0, 0);

                    const toDateEndOfDay = new Date(dates.toDate);
                    toDateEndOfDay.setHours(23, 59, 59, 999);

                    params.salesDate = {
                        $gte: new Date(fromDateStartOfDay),
                        $lte: new Date(toDateEndOfDay),
                    };
                }
                const filters = ["saleCategoryName", "name"];

                if (!searchType || searchType !== "customer") {
                    filters.push("type");
                } else {
                    params.type = {$ne: "patient"};
                }
                const data = await fetchSale({
                    ...params,
                    regExFilters: filters,
                });
                setCustomerData(data.data);
                resolve(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        });
    };

    const fetchcustomerdata = () => {
        const allrecordofcustomer = customerData.filter(
            (item) => item._id == salesId
        );
        allrecordofcustomer.map((i) => {
            setCustomerDetails({
                customerName: i.customerId?.name,
                customerState: i.customerId?.state,
                customerNumber: i.customerId?.mobile,
                customerInvoice: i.invoiceNumber,
                purchaseDate: i.purchaseDate,
                trackingDetails: i.trackingDetails,
                shipThrough: i.shipThrough,
                taxableAmount: i.taxableAmount,
                cgstAmount: i.cgstAmount,
                sgstAmount: i.sgstAmount,
                igstAmount: i.igstAmount,
                netAmount: i.netAmount,
                totalAmount: i.totalAmount,
                gstAmount: i.gstAmount,
                paidAmount: i.paidAmount,
                customDiscount: i.customDiscount,
                cutOffAmount: i.cutOffAmount,
            });
            setPaymentRecord(i.paidToUser);
            return;
        });
    };

    const fetchProductReport = async () => {
        try {
            let config = {
                params: {salesId},
                ...(await getToken()),
            };

            dispatch(showLoader());
            const response = await axios.get(getProductReport(), config);
            setReport(response.data.data);
            dispatch(hideLoader());
        } catch (error) {
            console.error("Error getting the data:", error);
        }
    };

    const statusChangeFunction = async (
        _id,
        trackingDetails,
        shipThrough,
        status
    ) => {
        try {
            let config = {
                params: {_id: _id},
                ...(await getToken()),
            };

            if (trackingDetails) {
                config.params.trackingDetails = trackingDetails;
            }
            if (shipThrough) {
                config.params.shipThrough = shipThrough;
            }
            if (status) {
                config.params.orderStatus = status;
            }
            dispatch(showLoader());
            const response = await axios.get(statusChangeUrl(), config);
            dispatch(hideLoader());
        } catch (error) {
            console.error("Error getting the data:", error);
        }
    };
    const paymentStatusUpdate = async (_id, paymentStatus) => {
        try {
            let config = {
                params: {_id: _id},
                ...(await getToken()),
            };
            if (paymentStatus) {
                config.params.paymentStatus = paymentStatus;
            }
            dispatch(showLoader());
            const response = await axios.get(statusChangeUrl(), config);
            dispatch(hideLoader());
        } catch (error) {
            console.error("Error getting the data:", error);
        }
    };

    useEffect(() => {
        apiRequest();
    }, []);

    useEffect(() => {
        if (salesId) {
            fetchProductReport();
            fetchcustomerdata();
        }
    }, [salesId]);

    const handleFilter = async () => {
        if (tableRef.current) {
            tableRef.current.reload();
        }
    };

    const events = {
        getState: () => {
            let resp = getStateByCountry('India');
            setStateList(resp);
        },
    };
    useEffect(() => {
        events.getState();
    }, []);


    const columns = [
        {
            title: "Sr. No",
            dataIndex: "serialNo",
            key: "serialNo",
            render: (text, record, index) => index + 1,
            width: 60,
        },
        {
            title: "Vendor",
            dataIndex: "customerId",
            key: "customerId",
            width: 120,
            render: (text) => <span>{text?.name}</span>,
        },
        {
            title: "Mobile",
            dataIndex: "customerId",
            key: "customerId",
            width: 100,
            render: (text) => <span>{text?.mobile}</span>,
        },
        {
            title: "Invoice Number",
            dataIndex: "invoiceNumber",
            key: "invoiceNumber",
            width: 80,
        },
        {
            title: "Sale Category",
            dataIndex: "saleCategoryName",
            key: "orderId",
            width: 80,
        },
        {
            title: "Order Id",
            dataIndex: "orderId",
            key: "saleCategoryName",
            width: 80,
        },
        {
            title: "Sales Date",
            dataIndex: "salesDate",
            key: "salesDate",
            render: (text) => <span>{moment(text).format("DD-MMM-YY")}</span>,
            width: 80,
        },
        {
            title: "State",
            dataIndex: "customerId",
            key: "customerId",
            render: (text) => <span>{text?.state}</span>,
            width: 80,
        },
        {
            title: "Total Qty",
            dataIndex: "quantity",
            key: "quantity",
            width: 46,
        },
        {
            title: "Total Amount",
            dataIndex: "totalAmount",
            key: "totalAmount",
            width: 80,
            render: (text) => <span>Rs. {text}</span>,
        },
        // {
        //   title: "Cutoff Amount",
        //   dataIndex: "cutOffAmount",
        //   key: "cutOffAmount",
        //   render: (text) => <span>Rs. {text}</span>,
        // },
        // {
        //   title: "Custom Discount",
        //   dataIndex: "customDiscount",
        //   key: "customDiscount",
        //   render: (text) => <span>Rs. {text}</span>,
        // },
        // {
        //   title: "Final Amount",
        //   dataIndex: "finalAmount",
        //   key: "finalAmount",
        //   render: (text) => <span>Rs. {text}</span>,
        // },
        {
            title: "Received Amount",
            dataIndex: "paidAmount",
            key: "paidAmount",
            width: 80,
            render: (text) => <span>Rs. {text}</span>,
        },
        // {
        //   title: "Balance",
        //   dataIndex: "balance",
        //   key: "balance",
        //   render: (text) => <span>Rs. {text}</span>,
        // },
        // {
        //     title: "Order Id",
        //     dataIndex: "orderId",
        //     width: 100,
        //     key: "orderId",
        // },
        {
            title: "Order Status",
            dataIndex: "orderStatus",
            width: 80,
            key: "orderStatus",
        },
        {
            title: "Payment Status",
            dataIndex: "paymentStatus",
            width: 80,
            key: "paymentStatus",
        },
        {
            title: "Actions",
            dataIndex: "description",
            key: "description",
            width: 150,
            fixed: "right",
            render: (text, record) => (
                <>
                    <Tooltip title={"View"}>
                        <a
                            className={"empty_btn"}
                            onClick={() => {
                                setIsModalOpen(true);
                                setSalesId(record._id);
                            }}
                        >
                            <i className={"fa fa-eye far"}/>
                        </a>
                    </Tooltip>
                    <Tooltip title={"Print"}>
                        <a
                            href={`/print-sale?_id=${record._id}`}
                            target="_blank"
                            className="m-2"
                            rel="noreferrer"
                        >
                            <img src={"./printer-50.png"} className="print" alt={""}/>
                        </a>
                    </Tooltip>
                    {record.orderStatus === "Processed" &&
                    record.customerId?.type !== "patient" ? (
                        <Tooltip title={"Ship?"}>
                            <a
                                className={"empty_btn"}
                                onClick={() => {
                                    setShippingModal(true);
                                    setSalesId(record._id);
                                }}
                            >
                                <i className={"fa fa-truck far"}/>
                            </a>
                        </Tooltip>
                    ) : null}
                    {record.orderStatus === "Shipped" && record.type !== "patient" ? (
                        <Tooltip title={"Deliver?"}>
                            <Popconfirm
                                title={
                                    "Are your sure, you want to change the order status to Delivered?"
                                }
                                okText="Yes"
                                cancelText="No"
                                onConfirm={() => {
                                    const status = "Delivered";
                                    statusChangeFunction(
                                        record._id,
                                        trackingDetails,
                                        shipThrough,
                                        status
                                    );
                                    setk(new Date().toString());
                                }}
                            >
                                <a className={"empty_btn"}>
                                    <i className={"fas fa-archive far"}></i>
                                </a>
                            </Popconfirm>
                        </Tooltip>
                    ) : null}
                    {record.paymentStatus == "Pending" && (
                        <Tooltip title={"Payment Done?"}>
                            <Popconfirm
                                title={"Are your sure, you want to done payment status?"}
                                okText="Yes"
                                cancelText="No"
                                onConfirm={() => {
                                    const paymentStatus = "Done";
                                    paymentStatusUpdate(record._id, paymentStatus);
                                    setk(new Date().toString());
                                }}
                            >
                                <a className={"empty_btn"}>
                                    <i className={"fas fa-rupee-sign"}/>
                                </a>
                            </Popconfirm>
                        </Tooltip>
                    )}
                    {record.orderStatus !== "Canceled" && (
                        <Tooltip title={"Cancel Sale"}>
                            <Popconfirm
                                title={"Are your sure, you want to cancel Sale?"}
                                okText="Yes"
                                cancelText="No"
                                onConfirm={() => {
                                    const status = "Canceled";
                                    statusChangeFunction(
                                        record._id,
                                        trackingDetails,
                                        shipThrough,
                                        status
                                    );
                                    setk(new Date().toString());
                                }}
                            >
                                <a className={"empty_btn"}>
                                    <i className={"fas fa-times far"}></i>
                                </a>
                            </Popconfirm>
                        </Tooltip>
                    )}
                    {record.status !== "Done" &&
                    record?.type != "royalty" &&
                    record?.type != "patient" && (
                        <Tooltip title={"Edit"}>
                            <a
                                onClick={() => {
                                    navigate(`/edit-sale?_id=${record._id}`);
                                }}
                            >
                                <img src={"./edit.svg"}/>
                            </a>
                        </Tooltip>
                    )}
                </>
            ),
        },
    ];

    const printFxn = () => {
        const printContents = document.getElementById("printable").innerHTML;
        const originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
        window.location.reload();
    };

    return (
        <PageHeader
            title={"Sales"}
            extraLink={[
                {
                    name: "Add Sale",
                    link: "/add-sales",
                },
            ]}
        >
            <div className="card">
                <div className="card-body">
                    <div className=" row">
                        <div className={"col-md-2 pe-0"} style={{marginTop: "13px"}}>
                            <SearchCustomer
                                type={["customer", "patient"]}
                                customerId={customerId}
                                callback={(clientData) => {
                                    if (clientData && clientData._id) {
                                        setCustomerId(clientData._id);
                                    }
                                }}
                            />
                        </div>
                        <div className="col-md-2 mb-2 pe-0" style={{paddingLeft: "7px"}}>
                            <label htmlFor="searchCategory" className="form-label"/>

                            <select
                                className="form-control "
                                placeholder="Search by Type"
                                style={{height: "45px"}}
                                onChange={(e) => setSearchType(e.target.value)}
                            >
                                <option value="">Select Type</option>
                                <option value="patient">Service</option>
                                <option value="customer">Medicines</option>
                            </select>
                        </div>
                        {/*<div className="col-md-2">*/}
                        {/*    <label htmlFor="searchCategory" className="form-label"/>*/}
                        {/*    <input*/}
                        {/*        type="text"*/}
                        {/*        className="form-control "*/}
                        {/*        name="orderStatus"*/}
                        {/*        value={searchByOrderStatus}*/}
                        {/*        placeholder="Search By Order Status"*/}
                        {/*        onChange={(e) => setSearchByOrderStatus(e.target.value)}*/}
                        {/*    />*/}
                        {/*</div>*/}
                        <div className="col-md-2 pe-0" style={{paddingLeft: "7px"}}>
                            <label htmlFor="searchCategory" className="form-label"/>

                            <select
                                className="form-control "
                                id="searchCategory"
                                placeholder="Search by Sale Category"
                                style={{height: "45px"}}
                                onChange={(e) => setSearchSalesCategory(e.target.value)}
                            >
                                <option value="">Select Category</option>
                                {saleCategory?.map((cat) => (
                                    <option key={cat.name} value={cat.name}>
                                        {cat.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-md-2 " style={{marginTop: 17}}>
                            <select
                                className="form-control "
                                id="searchCategory"
                                placeholder="Search by Type"
                                style={{height: "45px"}}
                                onChange={(e) => setSearchByPaymentStatus(e.target.value)}
                            >
                                <option value="">Select Payment Status</option>
                                <option value="Pending">Pending</option>
                                <option value="Done">Done</option>
                            </select>
                        </div>
                        <div className="col-md-2 " style={{marginTop: 17}}>
                            <select
                                className="form-control "
                                id="searchCategory"
                                placeholder="Search by Type"
                                style={{height: "45px"}}
                                onChange={(e) => setSearchByOrderStatus(e.target.value)}
                            >
                                <option value="">Select Order Status</option>
                                <option value="Canceled">Canceled</option>
                                <option value="Shipped">Shipped</option>
                                <option value="Delivered">Delivered</option>
                                {/*<option value="Processed">Processed</option>*/}
                            </select>
                        </div>
                        <div className="col-md-2 " style={{marginTop: 17}}>
                            <select
                                placeholder="Search by State"
                                style={{ height: "45px" }}
                                className="form-control"
                                value={searchByState.stateName} // Assuming searchByState is your state object
                                onChange={(e) => {
                                    const selectedState = stateList.find(state => state.name === e.target.value);
                                    setSearchByState({
                                        ...searchByState,
                                        stateName: selectedState ? selectedState.name : '',
                                        stateCode: selectedState ? selectedState.stateCode : ''
                                    });
                                }}
                                required
                            >
                                <option value="">Select State</option>
                                {stateList.map((state) => (
                                    <option key={state.name} value={state.name}>
                                        {state.name}
                                    </option>
                                ))}
                            </select>

                        </div>
                        <div className="col-md-2">
                            <label htmlFor="searchCategory" className="form-label"/>
                            <input
                                type="date"
                                className="form-control "
                                name="name"
                                value={dates.fromDate}
                                onChange={(e) =>
                                    setDates({...dates, fromDate: e.target.value})
                                }
                            />
                        </div>
                        <div
                            className="col-md-2"
                            style={{marginBottom: 10, marginTop: 5}}
                        >
                            <label htmlFor="searchCategory" className="form-label"/>
                            <input
                                type="date"
                                className="form-control "
                                name="name"
                                value={dates.toDate}
                                onChange={(e) => setDates({...dates, toDate: e.target.value})}
                            />
                        </div>
                    </div>
                    <div className="col-md-2 search-buttons mb-2">
                        <div className="search-wrap">
                            <button className="btn btn-info" onClick={handleFilter}>
                                Search
                            </button>
                        </div>
                        <div className="search-wrap">
                            <button className="btn btn-success" onClick={printFxn}>
                                Print
                            </button>
                        </div>
                    </div>
                    <div className={"saleTable"} id="printable">
                        <Table
                            apiRequest={apiRequest}
                            columns={columns}
                            ref={tableRef}
                            key={k}
                            position={"top"}
                            pagination={{...DefaultTablePagination(), defaultPageSize: 50}}
                            extraProps={{scroll: {x: 100}, }}
                        />
                    </div>
                </div>
            </div>
            {isModalOpen ? (
                <>
                    <ProductReportList
                        visible={isModalOpen}
                        Report={Report}
                        onClose={() => {
                            setIsModalOpen(false);
                            setReport([]);
                            setSalesId("");
                        }}
                        paymentRecord={paymentRecord}
                        customerDetails={customerDetails}
                    />
                </>
            ) : null}

            <Modal
                title={"Shipping"}
                width={"40%"}
                visible={shippingModal}
                onClose={() => {
                    setShippingModal(false);
                }}
            >
                <div>
                    <InputBox>
            <textarea
                className={"form-control"}
                type="text"
                value={trackingDetails}
                placeholder="Tracking Details..."
                onChange={(e) => setTrackingDetails(e.target.value)}
            />
                    </InputBox>
                    <InputBox className="mb-3 mt-3">
                        <select
                            style={{height: "45px"}}
                            className="form-control"
                            value={shipThrough}
                            onChange={(e) => setShipThrough(e.target.value)}
                            required="true"
                        >
                            <option value="">Select Ship Through</option>
                            {shipthrough?.map((ship) => (
                                <option key={ship.name} value={ship.name}>
                                    {ship.name}
                                </option>
                            ))}
                        </select>
                    </InputBox>
                    <div className="text-end">
                        <button
                            className="btn btn-info"
                            onClick={() => {
                                setShippingModal(false);
                                let status = "Shipped";
                                statusChangeFunction(
                                    salesId,
                                    trackingDetails,
                                    shipThrough,
                                    status
                                );
                                setTrackingDetails("");
                                setk(new Date().toString());
                            }}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </Modal>
        </PageHeader>
    );
}

export default Sale;
