import React, { useEffect, useRef, useState } from "react";
import { Table } from "../../components/Elements/appUtils";
import PageHeader from "../../components/Elements/pageHeader";
import { fetchVisits } from "./actions";
import moment from "moment";
import { PrintSaleUrl } from "./api";
import { getToken } from "../../request";
import axios from "axios";
import { States } from "../../components/_utils/appUtils";
const PrintSale = (props) => {
  const [allDetail, setAllDetail] = useState([]);
  const [saleRecord, setSaleRecord] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState();
  let id;
  const apiRequest = () => {
    return new Promise(async (resolve) => {
      try {
        let searchParams = new URLSearchParams(window.location.search);
        id = searchParams.get("_id");

        const response = await axios.get(PrintSaleUrl(id), getToken());
        console.log(response.data, "_id");
        console.log(response.data.sale, "idss   ");
        setAllDetail(response.data.datas);
        setInvoiceNumber(response.data.sale);
        setSaleRecord(response.data.sale);

        console.log("Invoice Numbers:", response.data);

        resolve(response);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    });
  };

  const getStateNameFromCode = () => {
    const foundState = States.find(
      (state) => state.stateCode == saleRecord.stateOfSupply
    );
   
    return foundState ? foundState.name : "";
  };

  function convertNumberToWords(num) {
    const units = [
      "",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
    ];
    const teens = [
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];
    const tens = [
      "",
      "Ten",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];

    if (num === 0) return "Zero";

    let words = "";

    if (num >= 1000 && num < 10000) {
      words += `${units[Math.floor(num / 1000)]} Thousand `;
      num %= 1000;
    }

    if (num >= 100 && num < 1000) {
      words += `${units[Math.floor(num / 100)]} Hundred `;
      num %= 100;
    }

    if (num >= 20 && num < 100) {
      words += `${tens[Math.floor(num / 10)]} `;
      num %= 10;
    }

    if (num > 10 && num < 20) {
      words += `${teens[(num % 10) - 1]} `;
      num = 0;
    }

    if (num > 0 && num < 10) {
      words += `${units[num]} `;
    }

    return words.trim();
  }

  useEffect(() => {
    apiRequest();
    setTimeout(() => {
      window.print();
    }, 1000);
  }, [id]);

  return (
    <>
      <div>
        <div className="PrintSaleUrl ">
          <p style={{ textAlign: "center", fontWeight: "bold" }}>Tax Invoice</p>
          <div className="row patient-details">
            <div className="col-sm-4 mt-1 p-0">
              <div className="row sizerow">
                <div className="col-sm-5 p-0">
                  <p className="size">
                    <b>Name</b>
                  </p>
                </div>
                <div className="col-sm-7 size">
                  <p className="size">
                    :&nbsp;&nbsp;{saleRecord.customerId?.name}
                  </p>
                </div>
              </div>
              <div className="row sizerow">
              </div>
              <div className="row sizerow">
                <div className="col-sm-5 p-0">
                  <p className="size">
                    <b>Mobile</b>
                  </p>
                </div>
                <div className="col-sm-7">
                  <p className="size">
                    :&nbsp;&nbsp;{saleRecord.customerId?.mobile}
                  </p>
                </div>
              </div>
              <div className="row sizerow">
                <div className="col-sm-5 p-0">
                  <p className="size">
                    <b>Address</b>
                  </p>
                </div>
                <div className="col-sm-7">
                  {" "}
                  <p className="size">
                    :&nbsp;{saleRecord.customerId?.address ?? ""}
                    {saleRecord.customerId?.state &&
                    saleRecord.customerId?.address
                      ? ", "
                      : ""}
                    &nbsp;{saleRecord.customerId?.state ?? ""}
                    {saleRecord.customerId?.city &&
                    (saleRecord.customerId?.state ||
                      saleRecord.customerId?.address)
                      ? ", "
                      : ""}
                    &nbsp;{saleRecord.customerId?.city ?? ""}
                  </p>
                </div>
              </div>
              <div className="row sizerow">
                <div className="col-sm-5 p-0">
                  <p className="size">
                    <b>GstIn</b>
                  </p>
                </div>
                <div className="col-sm-7">
                  {" "}
                  {saleRecord.customerId?.gstIn && (
                    <p className="size">
                      :&nbsp;&nbsp;{saleRecord.customerId.gstIn}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="col-sm-8 mt-1">
              <div className="row sizerow">
                <div className="col-sm-4">
                  <p className="size">
                    <b>Invoice</b>
                  </p>
                </div>
                <div className="col-sm-8">
                  <p className="size">
                    : &nbsp;&nbsp; {saleRecord.invoiceNumber}
                  </p>
                </div>
              </div>
              <div className="row sizerow">
                <div className="col-sm-4">
                  <p className="size">
                    <b>Invoice Date</b>
                  </p>
                </div>
                <div className="col-sm-8">
                  <p className="size">
                    :&nbsp;&nbsp;{" "}
                    {moment(saleRecord.salesDate).format("DD/MM/YYYY")}
                  </p>
                </div>
              </div>
              <div className="row sizerow">
                <div className="col-sm-4">
                  <p className="size">
                    <b>Place of supply</b>
                  </p>
                </div>
                <div className="col-sm-8">
                  <p className="size">
                  :&nbsp;&nbsp;{" "} {getStateNameFromCode(saleRecord.stateOfSupply)}

                  </p>
                </div>
              </div>
            </div>
          </div>

          <p className="title mt-1 " style={{ fontSize: "13px" }}></p>

          <table class="table table-bordered mt-4">
            <thead>
              <tr style={{ fontSize: "11px" }}>
                <th>
                  <b>Sr</b>
                </th>
                <th className="table-heading" style={{ fontSize: "11px" }}>
                  <b>Name</b>
                </th>
                <th className="table-heading" style={{ fontSize: "11px" }}>
                  <b>HSN</b>
                </th>
                <th className="table-heading" style={{ fontSize: "11px" }}>
                  <b>Exp.</b>
                </th>
                <th className="table-heading" style={{ fontSize: "11px" }}>
                  <b>Batch</b>
                </th>
                <th className="table-heading" style={{ fontSize: "11px" }}>
                  <b>Qty</b>
                </th>
                <th className="table-heading" style={{ fontSize: "11px" }}>
                  <b>UoM</b>
                </th>

                <th className="table-heading" style={{ fontSize: "11px" }}>
                  <b>Rate</b>
                </th>
                <th className="table-heading" style={{ fontSize: "11px" }}>
                  <b>Total</b>
                </th>
                <th className="table-heading" style={{ fontSize: "11px" }}>
                  <b>Dis</b>
                </th>
                <th className="table-heading" style={{ fontSize: "11px" }}>
                  <b>Tax</b>
                </th>

                {saleRecord.cgstAmount !== 0 && saleRecord.sgstAmount !== 0 ? (
                  <>
                    <th className="table-heading" style={{ fontSize: "11px" }}>
                      <b>CGST</b>
                    </th>
                    <th className="table-heading" style={{ fontSize: "11px" }}>
                      <b>SGST</b>
                    </th>
                  </>
                ) : saleRecord.cgstAmount === 0 ||
                  saleRecord.sgstAmount === 0 ? (
                  <th className="table-heading" style={{ fontSize: "11px" }}>
                    <b>IGST</b>
                  </th>
                ) : null}

                <th
                  className="table-heading"
                  style={{ fontSize: "11px", width: "20px" }}
                >
                  <b>Net Amt</b>
                </th>
              </tr>
            </thead>

            <tbody>
              {allDetail.map((i, index) => (
                <tr key={index}>
                  <td style={{ fontSize: "13px" }}>{index + 1}</td>

                  <td style={{ fontSize: "13px" }}>{i.productName}</td>
                  <td style={{ fontSize: "13px" }}>{i.productId?.hsnCode}</td>
                  <td style={{ fontSize: "13px" }}>{i.expiryDate}</td>
                  <td style={{ fontSize: "13px" }}>{i.batchNo}</td>
                  <td style={{ fontSize: "13px" }}>{i.quantity}</td>
                  <td style={{ fontSize: "13px" }}>{i.productId?.unitCode}</td>
                  <td style={{ fontSize: "13px" }}> ₹ {i.price}</td>
                  <td style={{ fontSize: "13px" }}> ₹ {i.price}</td>
                  <td style={{ fontSize: "13px" }}>{i.discount}</td>
                  <td style={{ fontSize: "13px" }}> ₹ {i.taxableAmount}</td>
                  {i.igstAmount !== 0 ? (
                    <>
                      {" "}
                      <td style={{ fontSize: "13px" }}>{i.igstAmount}</td>
                    </>
                  ) : i.sgstAmount !== 0 && i.cgstAmount !== 0 ? (
                    <>
                      <td style={{ fontSize: "13px" }}>₹ {i.cgstAmount}</td>
                      <td style={{ fontSize: "13px" }}>₹ {i.sgstAmount}</td>
                    </>
                  ) : (
                    ""
                  )}
                  <td style={{ fontSize: "13px" }}> ₹ {i.netAmount}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="Total-section mt-4">
          <div className="row">
            <div className="col-sm-7 mt-1">
              <h6 style={{ fontSize: "13px" }}>Payment Details:</h6>
              <div
                style={{
                  borderBottom: "1px solid #000",
                  marginTop: "7px",
                  width: "300px",
                }}
                />

              <div
                style={{
                  borderBottom: "1px solid #000",
                  marginTop: "10px",
                  width: "300px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <span style={{ fontSize: "13px" }}>PAYMODE</span>
                <span style={{ fontSize: "13px" }}>AMOUNT</span>
              </div>

              <div>
                {saleRecord?.paidToUser?.map((val, innerIndex) => (
                  <div
                    key={innerIndex}
                    style={{
                      marginTop: "10px",
                      width: "300px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span style={{ fontSize: "13px" }}>{val.paymentType}</span>
                    <span style={{ fontSize: "13px" }}>{val.amount}</span>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-sm-5 border-design">
              <div className="row">
                <div className="col-sm-12">
                  <div className="row">
                    <div className="col-sm-7">
                      <div style={{ textAlign: "right" }}>
                        <p style={{ fontSize: "13px" }}>
                          <b>Taxable Amt :</b>
                        </p>
                        {saleRecord.cgstAmount !== 0 &&
                        saleRecord.sgstAmount !== 0 ? (
                          <>
                            <p style={{ fontSize: "13px" }}>
                              <b>CGST Total :</b>{" "}
                            </p>
                            <p style={{ fontSize: "13px" }}>
                              <b>SGST Total :</b>{" "}
                            </p>
                          </>
                        ) : (
                          <p style={{ fontSize: "13px" }}>
                            <b>IGST Total : </b>
                          </p>
                        )}
                        <p style={{ fontSize: "13px" }}>
                          <b>Total Amt :</b>
                        </p>
                        <p style={{ fontSize: "13px" }}>
                          <b>Round Off Amt :</b>
                        </p>
                        <p style={{ fontSize: "13px" }}>
                          <b>Cash Discount :</b>
                        </p>
                        <p style={{ fontSize: "13px" }}>
                          <b>Final Amt :</b>
                        </p>
                        <div
                        style={{
                          borderBottom: "1px solid #000",
                          marginTop: "7px",
                          width: "185%",
                          marginLeft: "10px",
                          textAlign: "right",
                        }}
                        />
                        <p style={{ fontSize: "13px" }}>
                          <b>Paid Amt :</b>
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-5">
                      <div style={{ textAlign: "right" }}>
                        <p style={{ fontSize: "13px" }}>
                          {" "}
                          ₹ {parseFloat(saleRecord.taxableAmount).toFixed(2)}
                        </p>
                        {saleRecord.cgstAmount !== 0 &&
                        saleRecord.sgstAmount !== 0 ? (
                          <>
                            <p style={{ fontSize: "13px" }}>
                              {" "}
                              ₹ {parseFloat(saleRecord.cgstAmount).toFixed(2)}
                            </p>
                            <p style={{ fontSize: "13px" }}>
                              {" "}
                              ₹ {parseFloat(saleRecord.sgstAmount).toFixed(2)}
                            </p>
                          </>
                        ) : (
                          <p style={{ fontSize: "13px" }}>
                            {" "}
                            ₹ {parseFloat(saleRecord.igstAmount).toFixed(2)}
                          </p>
                        )}
                        <p style={{ fontSize: "13px" }}>
                          ₹ {parseFloat(saleRecord.netAmount).toFixed(2)}
                        </p>
                        <p style={{ fontSize: "13px" }}>
                          ₹ {parseFloat(saleRecord.cutOffAmount).toFixed(2)}
                        </p>
                        <p style={{ fontSize: "13px" }}>
                          ₹ {parseFloat(saleRecord.customDiscount).toFixed(2)}
                        </p>
                        <p style={{ fontSize: "13px" }}>
                          ₹ {parseFloat(saleRecord.finalAmount).toFixed(2)}
                        </p>
                        <p style={{ fontSize: "13px" }}>
                          ₹ {saleRecord.paidAmount}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="Amount-section" style={{ fontSize: "13px" }}>
            <b>Amount In Words :</b> Rupees{" "}
            {convertNumberToWords(saleRecord.netAmount)} only
          </div>
          {saleRecord.type !== "patient"?
            <div className="footer-section mt-4">
              <div className="row">
                <div className="col-sm-6">
                  <div
                      style={{
                        borderBottom: "1px solid #000",
                        marginTop: "15%",
                        width: "160px",
                      }}
                  />
                  <p className="mt-3">Pharmacist Signature</p>
                </div>
              </div>
            </div>:null
          }
        </div>
      </div>
    </>
  );
};

export default PrintSale;
