import { apiUrl } from "../../../setting";

export const getCustomerDataUrl = () => {
    return `${apiUrl}/routes/customer/customerList`;
};


export const getBank = () => {
    return `${apiUrl}/routes/customer/getBank`;
};

export const addPurchaseUrl = () => {
    return `${apiUrl}/routes/purchase/purchaseProduct`;
};

export const getPurchaseUrl = () => {
    return `${apiUrl}/routes/purchase/getAllPurchase`;
};


export const getGroupCustomerDataUrl = () => {
    return `${apiUrl}/routes/customer/getCustomerDataByGroupId`;
};

export const getProductReport = () => {
    return `${apiUrl}/routes/purchase/getProductReport`;
};

export const deletePurchaseReport = () => {
    return `${apiUrl}/routes/purchase/deletePurchase`;
};
export const statusChangeUrl = () => {
    return `${apiUrl}/routes/sale/statusUpdate`;
};

export const findRecentProductReportUrl = () => {
    return `${apiUrl}/routes/purchase/latestPrice`;
};

