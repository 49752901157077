import React, { useEffect, useRef, useState } from "react";
import {
  Modal, notification,
  Table,
  Popconfirm
} from "../../components/Elements/appUtils";
import {
  fetchPurchase,
  fetchRequestedProductReportFxn,
} from "./actions";
import PageHeader from "../../components/Elements/pageHeader";
import moment from "moment";
import { getToken } from "../../request";
import axios from "axios";
import {approveRequestURl, rejectStockRequest} from "./api";
import { getItemLocalstorage } from "../../components/_utils/_utils";
import { useNavigate } from "react-router-dom";

function StockRequests() {
  const [stockData, setStockData] = useState("");
  const [filterdData, setFilteredData] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [searchVendor, setSearchVendor] = useState("");
  const [searchRequestDate, setSearchRequestDate] = useState("");
  const [searchState, setSearchState] = useState("");
  const [searchStatus, setSearchStatus] = useState("");

  const userType = getItemLocalstorage("user")["userType"];
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [requestId, setRequestId] = useState(null);
  let tableRef = useRef();
  const checkUserType = getItemLocalstorage("user")["userType"];

  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      try {
        const data = await fetchPurchase({ ...params });
        resolve(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    });
  };

  const StockRequestReports = () => {
    return new Promise(async (resolve) => {
      try {
        const datas = await fetchRequestedProductReportFxn({
          requestId: requestId,
          results: 1000,
          count: 1000
        });
        resolve(datas);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    });
  };

  const approveRequest = async (saleId, requestId) => {
    try {
      let config = {
        ...(await getToken()),
      };
      const data = await axios.get(
        approveRequestURl(saleId, requestId),
        config
      );
      if (data) {
        setTimeout(() => {
          navigate("/purchase");
        }, 1000);
      }
    } catch (error) {
      console.error("Error getting the data:", error);
    }
  };

  const rejectStockRequestFxn = async (requestId) => {
    try {
      let config = {
        ...(await getToken()),
      };
      const data = await axios.get(
        rejectStockRequest(requestId),
        config
      );
      if (data) {
        notification.success({message:'Stock Request Rejected'})
        tableRef.current.reload()
      }
    } catch (error) {
      notification.error({message:'Error Rejecting Stock Request'})
      console.error("Error getting the data:", error);
    }
  };

  const ConvertSale = (id, branchId) => {
    setTimeout(() => {
      navigate(`/add-sales?id=${id}&clientId=${branchId}`);
    }, 1000);
  };

  useEffect(() => {
    apiRequest();
  }, []);

  useEffect(() => {
    StockRequestReports(requestId);
  }, [requestId]);

  const columns = [
    {
      title: "Sr. No",
      dataIndex: "serialNo",
      key: "serialNo",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Vendor",
      dataIndex: "customerId",
      key: "customerId",
      render: (text) => <span>{text?.name}</span>,
    },
    {
      title: "Request Date",
      dataIndex: "requestDate",
      key: "requestDate",
      render: (text, record) => (
        <span>{moment(record?.requestDate).format("DD/MMM/YY")}</span>
      ),
    },
    {
      title: "State",
      dataIndex: "customerId",
      key: "customerId",
      render: (text) => <span>{text?.state}</span>,
    },
    {
      title: "Total Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => {
        if (text === 'pending') {
          return <span>Pending</span>;
        } else if (text === 'processed') {
          return <span>Processed</span>;
        }else if (text === 'approved') {
          return <span>Approved</span>;
        } else {
          return <span>{text}</span>;
        }
      }
    },
    {
      title: "Actions",
      dataIndex: "description",
      key: "description",
      render: (text, record) => (
        <>
          <button
            onClick={() => {
              setIsModalOpen(true);
              setRequestId(record._id);
            }}
            className="btn btn-info btn-xs m-2"
          >
            View
          </button>
          {userType == "company" && record.status == "pending" ? (
            <button
              className="btn btn-success btn-xs"
              onClick={() => {
                setRequestId(record._id);
                ConvertSale(record._id, record.branchId);
              }}
            >
              Convert
            </button>
          ) : null}

          {userType == "branch" && record.status == "processed" ? (
            <button
              className="btn btn-success btn-xs"
              onClick={() => {
                setRequestId(record._id);
                approveRequest(record.saleId, record._id);
              }}
            >
              Approve
            </button>
          ) : null}

          {userType == "company" && record.status == "pending" ? (
              <Popconfirm
                  title={"Are your sure, you want to Reject Stock Request?"}
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => {
                    rejectStockRequestFxn(record._id);
                  }}
              >
                <button
                    className="btn btn-danger btn-xs m-2"
                >
                  Reject
                </button>
              </Popconfirm>
          ) : null}
        </>
      ),
    },
  ];

  const columns1 = [
    {
      title: "Sr. No",
      dataIndex: "serialNo",
      key: "serialNo",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Product Name",
      dataIndex: "productName",
      key: "productName",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "HSN",
      dataIndex: "hsn",
      key: "hsn",
    },
    {
      title: "UOM",
      dataIndex: "uom",
      key: "uom",
    },
  ];

  const filterStock = () => {
    setShowFilter(true);
    if (!searchVendor && !searchRequestDate && !searchState && !searchStatus) {
      setStockData([]);
    }
    const filteredStock = stockData?.filter((stock) => {
      stock.customerId?.name
        ?.toLowerCase()
        .includes(searchVendor.toLowerCase()) &&
        stock.requestDate
          ?.toLowerCase()
          .includes(searchRequestDate.toLowerCase()) &&
        stock.customerId?.state
          ?.toLowerCase()
          .includes(searchState.toLowerCase()) &&
        stock.status?.toLowerCase().includes(searchStatus.toLowerCase());
    });
    setStockData(filteredStock);
  };

  const clearStockFilters = () => {
    setSearchVendor("");
    setSearchRequestDate("");
    setSearchState("");
    setSearchStatus("");
    setShowFilter(false);
    setStockData(filterdData);
    if (tableRef.current.reload) {
      tableRef.current.reload();
    }
  };

  const printFxn = () => {
    const printContents = document.getElementById("printable").innerHTML;
    const originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload();
  };
  return (
    <PageHeader
      title={"Stock Request"}
      extraLink={
        checkUserType == "headBranch" || checkUserType == "company"
          ? []
          : [
              {
                name: "Add Stock",
                link: "/add-stockRequest",
              },
            ]
      }
    >
      <div className="card">
        <div className="card-body">
          <div className="mb-3 row">
            <div className="col-md-2 mb-2 pe-0" style={{ paddingLeft: "7px" }}>
              <label htmlFor="searchVendor" className="form-label" />
              <input
                type="text"
                className="form-control search-filters"
                id="searchVendor"
                placeholder="Search by Vendor"
                value={searchVendor}
                onChange={(e) => setSearchVendor(e.target.value)}
              />
            </div>

            <div className="col-md-2 mb-2 pe-0" style={{ paddingLeft: "7px" }}>
              <label htmlFor="searchRequestDate" className="form-label" />
              <input
                type="text"
                className="form-control search-filters"
                id="searchRequestDate"
                placeholder="Search by RequestDate"
                value={searchRequestDate}
                onChange={(e) => setSearchRequestDate(e.target.value)}
              />
            </div>

            <div className="col-md-2 mb-2 pe-0" style={{ paddingLeft: "7px" }}>
              <label htmlFor="searchState" className="form-label" />
              <input
                type="text"
                className="form-control search-filters"
                id="searchState"
                placeholder="Search by State"
                value={searchState}
                onChange={(e) => setSearchState(e.target.value)}
              />
            </div>
            <div className="col-md-2 mb-2 pe-0" style={{ paddingLeft: "7px" }}>
              <label htmlFor="searchStatus" className="form-label" />
              <input
                type="text"
                className="form-control search-filters"
                id="searchStatus"
                placeholder="Search by Status"
                value={searchStatus}
                onChange={(e) => setSearchStatus(e.target.value)}
              />
            </div>
          </div>

          <div className="mb-3 search-buttons">
            <div className="search-wrap pe-2">
              <button className="btn btn-info" onClick={filterStock}>
                Search
              </button>
            </div>
            <div className="search-wrap">
              <button className="btn btn-secondary" onClick={clearStockFilters}>
                Clear
              </button>
            </div>

          </div>

          <Table apiRequest={apiRequest} columns={columns} ref={tableRef} />
        </div>
      </div>

      {isModalOpen && (
        <Modal
          title={
            <div className={'d-flex'}>
              {"Products"}
              <div className="search-wrap"
              style={{marginRight:'15px'}}
              >
                <button className="btn btn-link" onClick={printFxn}>
                  Print
                </button>
              </div>
            </div>
          }
          width={"50%"}
          visible={isModalOpen == true}
          onClose={() => setIsModalOpen(false)}
        >
          <div id="printable">
            <Table
                apiRequest={StockRequestReports}
                columns={columns1}
                ref={tableRef}
                pagination={false}
            />
          </div>
        </Modal>
      )}
    </PageHeader>
  );
}

export default StockRequests;
