import {apiUrl} from "../../../setting";

export const getCompanyDataUrl = () => {
    return `${apiUrl}/routes/company/getCompanyData`;
};

export const addCompanyUrl = () => {
    return `${apiUrl}/routes/company/createCompany`;


};
export const singleCompanyUrl = (CompanyId) => {
    return apiUrl + "/routes/company/getSingleCompany/" + CompanyId;
};
export const updateCompanyUrl = () => {
    return `${apiUrl}/routes/company/updateCompany`;
  };

  export const updateProfileUrl = () => {
    return `${apiUrl}/routes/company/updateProfile`;
  };
  export const singleProfileUrl = () => {
    return `${apiUrl}/routes/company/getSingleProfile`;
};
  
  
